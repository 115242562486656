import styles from "./footer-simple.module.scss"
import { withLanguage } from "../../../../../i18n/with-language"
import React from "react"
import { Link } from "gatsby"
import LogoComponent from "../../logo"

const FooterSimpleComponent = ({ lang }) => (
  <footer className={styles.footer}>
    <div className={styles.menu}>
      <div className={`container ${styles.menuContainer}`}>
        <div className={styles.footer_credentials}>
          <div className={`${styles.item} ${styles.logo}`}>
            <Link to={lang === "ru" ? "/" : "/en"}>
              <LogoComponent alt='Paraplan Logo'/>
            </Link>
          </div>
        </div>
        <div className={styles.nav}>
          <div className={styles.navItem}>
            <a href={"#features"}>{lang === "en" ? "Features" : "Funktionen"}</a>
          </div>
          <div className={styles.navItem}>
            <a href={"#pricing"}>{lang === "en" ? "Pricing" : "Preise"}</a>
          </div>
          {
            lang === "en" && <div className={styles.navItem}>
              <Link to={"/en/policy"}>Privacy Policy</Link>
            </div>
          }
        </div>
        <div className={styles.footerLegal}>
          Copyright © 2008-{new Date().getFullYear()} Haulmont. All rights reserved.
        </div>
      </div>
    </div>
  </footer>
)

export default withLanguage(FooterSimpleComponent)
