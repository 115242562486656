import React from "react"

import styles from "./input.module.scss"
import Translate from "../../../i18n/Translate"

function getErrorCode(validation, name) {
  return validation && !validation.success && validation.fieldErrorCodeList[name]
}

function hasError(validation, name) {
  return !!getErrorCode(validation, name)
}

const Input = ({ validation, name, inputClassName, ...props }) => (
  <div className={`${styles.wrapper} ${hasError(validation, name) ? styles.hasError : ""}`}>
    <input className={`${styles.input} ${inputClassName}`} {...props} />
    <div className={styles.error}>
      <Translate>
        {translate => {
          const errorCode = getErrorCode(validation, name)
          return errorCode ? translate(`field.${errorCode}`) : null
        }}
      </Translate>
    </div>
  </div>
)

export default Input
