import React from "react"

import styles from "./footer-credentials.module.scss"
import LogoComponent from "../logo"
import FooterLegal from "./footer-legal"
import { Link } from "gatsby"
import Email from "../../../shared/email"
import { withLanguage } from "../../../../i18n/with-language"

const FooterCredentials = ({ lang }) => (
  <div className={styles.footer_credentials}>
    <div className={`${styles.item} ${styles.logo}`}>
      <Link to={lang === "ru" ? "/" : "/en"}>
        <LogoComponent alt='Логотип Параплан' />
      </Link>
    </div>
    <div className={`${styles.item} ${styles.email}`}>
      <div className={styles.supp}>
        <p className={styles.email_text}>Служба заботы:</p>
        <Email name={"sales"} domain={"paraplancrm"} tld={"ru"}></Email>
      </div>
      <div className={styles.messengers}>
        <a className={styles.messenger} href={"https://wa.me/79991709714"} target={"_blank"}
          rel={"noopener nofollow"}>
          <img src={require("../../../../images/support/whatsapp.svg")} alt="WhatsApp" />
        </a>
        <a className={styles.messenger} href={"https://t.me/paraplancrm"} target={"_blank"}
          rel={"noopener nofollow"}>
          <img src={require("../../../../images/support/telegram.svg")} alt="Telegram" />
        </a>
      </div>
      <FooterLegal className={styles.credentialsLegal}/>
    </div>
  </div>
)

export default withLanguage(FooterCredentials)
