import React from "react"
import styles from "./header.module.scss"

import HeaderMenuComponent from "./header-menu"
import LogoComponent from "../logo"
import { Link } from "gatsby"
import { withLanguage } from "../../../../i18n/with-language"
import HeaderWebinarBanner from "./header-webinar-banner"

function isSticky(threshold = 0) {
  return typeof window !== "undefined" ? window.pageYOffset > threshold : false
}

function calculateTop(sticky, forceFixed, threshold) {
  let top = 0
  if (!sticky || (forceFixed && !isSticky(threshold))) {
    top = threshold
  }
  return top
}

class HeaderComponent extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      top: 0,
      sticky: props.forceFixed || isSticky(props.threshold || 0),
      headerColor: props.headerColor || "#6D20D2",
      pageClassName: props.pageClassName || ''
    }
  }

  repositionHeader = () => {
    const { forceFixed, threshold } = this.props
    const sticky = forceFixed || isSticky(threshold || 0)
    const top = calculateTop(sticky, forceFixed, threshold)
    this.setState({ sticky, top })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.repositionHeader)
    setTimeout(() => this.repositionHeader(), 500)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.repositionHeader)
  }

  render() {
    const { sticky, headerColor, pageClassName } = this.state
    const { threshold, forceFixed } = this.props
    const top = calculateTop(sticky, forceFixed, threshold)

    return (
      <div className={`${styles.header} ${sticky ? styles.sticky : ""}`}
           style={{
             background: sticky ? headerColor : "transparent",
             position: sticky ? (threshold !== 0 && top === threshold ? "absolute" : "fixed") : null,
             top,
             boxShadow: `${pageClassName === "about" ? "none" : ""}`
           }}>
        <div className={styles.container}>
          <Link to={this.props.lang === "ru" ? "/" : "/en"}>
            <LogoComponent className={styles.logo} pageClassName={pageClassName}/>
          </Link>
          <HeaderMenuComponent headerColor={headerColor} pageClassName={pageClassName} className={styles.mobileMenu}/>
        </div>
      </div>
    )
  }

}

export default withLanguage(HeaderComponent)
