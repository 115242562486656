import React from "react"

import FeedbackOverlayService from "../../../services/feedback-overlay-service"
import pricingInfo from "../../pages/main/pricing/pricing-info"
import Translate from "../../../i18n/Translate"
import { LangContext } from "../../lang-context"

export function getModalTitle(success) {
  if (success) {
    return <Translate id={"form.thankYouHeader"}/>
  }

  const { action, block } = FeedbackOverlayService._from || {}
  if (block === "pricing") {
    return getPricingHeaders(action)
  } else {
    //return <Translate id={"form.contactUs"}/>
  }
}

export function getModalDescription(success, registrationTokenSentToCustomer) {

  if (!success) {
    return (<div>{htmlStr()}<Translate id={"form.formMessage"}/></div>)
  }
  return <Translate id={registrationTokenSentToCustomer ? "form.thankYouMessageWithEmail" : "form.thankYouMessage"}/>
}

function htmlStr() {
  //let freeSummerMessage = "<span style='font-size: 36px;font-weight: 500;line-height: 43px;'>Откройте возможности Параплан!<br><br></span>"
  //let freeSummerMessage = '<p style="font-size: 36px;font-weight: 500;line-height: 43px;">Откройте возможности Параплан!</p><p style="text-align:left;font-weight:500;margin-bottom:5px;font-size:22px;">При первой покупке подписки на:</p><ul style="text-align:left; list-style:none; margin-left:0;"><li style="margin-bottom:0">- тариф "Опти" и "Макси" на 3, 6 или 12 месяцев <b>скидка 10%</b></li><li style="margin-bottom:0">- тариф "Франшизы" <b>скидка 5%</b></li></ul>'
  let freeSummerMessage = `
    <p style="font-size: 36px;font-weight: 500;line-height: 43px;">Откройте возможности Параплан!</p>
    <p style="text-align:center;font-weight:500;margin-bottom:5px;font-size:22px;">При первой покупке подписки на:</p>
    <div class="mobile-popup">
      <div>
        <p><b>тариф “Опти” и “Макси”</b></p>
        <p>на 3, 6 или 12 месяцев</p>
        <p><b><span style="color: #E75D49;">скидка до 25%</span></b> + подарочные дни</p>
      </div>
      <div>
        <p><b>тариф “Франшизы”</b></p>
        <p>на любой период</p>
        <p><b><span style="color: #E75D49;">скидка до 10%</span></b> + подарочные дни</p>
      </div>
    </div>
    `
  // let freeSummerMessage = `
  //     <p style="font-size: 36px;font-weight: 500;line-height: 43px;">Откройте возможности Параплан!</p>
  //     <p style="font-weight:500;margin-bottom:10px;font-size:22px;color:#E75D49;">При первой покупке подписки на:</p>
  //     <div class="actionpopup"><nobr>3 месяца:</nobr>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  //       <div class="discwrapper">
  //         <div class="discwhite">скидка 1 100 ₽</div>
  //       </div>
  //     </div>
  //     <div class="actionpopup"><nobr>6 месяцев:</nobr>&nbsp;&nbsp;&nbsp;&nbsp;
  //       <div class="discwrapper">
  //         <div class="discwhite">скидка 2 300 ₽</div>
  //         <div class="greenstr">+ 7 подарочных дней&nbsp;</div>
  //       </div>
  //     </div>
  //     <div class="actionpopup"><nobr>12 месяцев:</nobr>&nbsp;&nbsp;
  //       <div class="discwrapper">
  //         <div class="discwhite">скидка 3 400 ₽</div>
  //         <div class="greenstr">+ 20 подарочных дней</div>
  //       </div>
  //     </div>
  // `
  //let freeSummerMessage = "<span style='font-size: 36px;font-weight: 500;line-height: 43px;'>АКЦИЯ БЕСПЛАТНОЕ ЛЕТО</span><br/><br/><div style='text-align: left'>При покупке подписки на:</div><ul style='text-align: left; margin-left:1em;'><li style='margin-bottom: 0'>1 месяц + 14 дней бесплатного пользования системой.</li><li style='margin-bottom: 0'>3 месяца + 30 дней бесплатного пользования системой и Личными кабинетами учеников.</li><li style='margin-bottom: 0'>12 месяцев + 90 дней бесплатного пользования системой и Личными кабинетами учеников.</li></ul>"
  return  (<div dangerouslySetInnerHTML={{ __html: freeSummerMessage }} />)
}



function getPricingHeaders(action) {
  return <LangContext.Consumer>
    {
      (lang) => {
        const { name } = pricingInfo(lang).find(info => info.code === action)
        return action !== "custom"
          ? <Translate>{translate => translate("form.pricingPlan")(name)}</Translate>
          : name
      }
    }
  </LangContext.Consumer>
}
