import React from "react"

import styles from "./footer.module.scss"
import FooterCredentials from "./footer-credentials"
import FooterMenuComponent from "./footer-menu"
import FooterSocial from "./footer-social"
import LogoComponent from "../logo"
import { Link } from "gatsby"
import FooterLinks from "./footer-links"
import FooterLegal from "./footer-legal"
import { withLanguage } from "../../../../i18n/with-language"

const FooterComponent = ({ lang }) => (
  <footer className={styles.footer}>
    <div className={styles.menu}>
      <div className={`container ${styles.menuContainer}`}>
        <FooterCredentials />
        <FooterMenuComponent />
      </div>
    </div>
    <div className={styles.links}>
      <div className={`container ${styles.linksContainer}`}>
        <FooterLinks className={styles.footerLinks} />
        <div className={styles.wrap}>
          <a className={styles.tgBtn} href="https://t.me/ParaplanCRMnews" target="_blank">Telegram канал</a>
          <div className={styles.social}>
            <FooterSocial />
          </div>
        </div>
        <div className={styles.support}>
          <div className={`${styles.emailContainer} ${styles.desctop}`}>
            <div className={styles.wrap}>
              <a href="mailto:support@paraplancrm.ru"> support@paraplancrm.ru</a>
              <a href="tel:88462110072">8 (846) 211-00-72</a>
            </div>
            <div className={styles.logo}>
              <Link to={lang === "ru" ? "/" : "/en"}>
                <LogoComponent alt='Логотип Параплан' />
              </Link>
            </div>
          </div>
          <div className={`${styles.emailContainer} ${styles.mobile}`}>
            <a href="tel:88462110072">8 (846) 211-00-72, </a> <a href="mailto:support@paraplancrm.ru"> support@paraplancrm.ru</a>
            <div className={styles.logo}>
              <Link to={lang === "ru" ? "/" : "/en"}>
                <LogoComponent alt='Логотип Параплан' />
              </Link>
            </div>
          </div>
        </div>
        <FooterLegal className={styles.footerLegalLinks} />
      </div>
    </div>
  </footer>
)

export default withLanguage(FooterComponent)
