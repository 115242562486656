import axios from "axios"

class SubscriberService {

  send(email, captcha) {
    const data = this.getSubscribeRequest(email)
    return axios.put("/api/public/email", data, {
      headers: {
        "Content-Type": "application/json",
        "captcha-response": captcha,
      },
    })
      .then(response => response.data)
      .catch(error => error.response.data)
  }

  getSubscribeRequest(email) {
    return {
      email: this.trimValue(email),
    }
  }

  trimValue(value) {
    const trimmed = value.trim()
    return trimmed.length ? trimmed : null
  }

}

export default new SubscriberService()