const PricingInfo = {
  ru: [
    {
      code: "base",
      name: "Базовый",
      color: "red",
      custom: false,
      price: {
        monthly: { ru: 1300, ua: 500, kz: 7700 },
        quarterly: { ru: 1150, ua: 450, kz: 6850 },
        yearly: { ru: 1000, ua: 400, kz: 6000 },
      },
      features: [
        feature("Клиентская база (до 50 учеников)"),
        feature("Интерактивное расписание"),
        feature("Учет посещений и абонементов"),
        feature("Учет доходов и расходов"),
        feature("Разграничение прав доступа сотрудников"),
        feature("Мобильная версия"),
      ],
    },
    {
      code: "optimal",
      name: "Оптимальный",
      color: "purple",
      custom: false,
      price: {
        monthly: { ru: 1800, ua: 700, kz: 10700 },
        quarterly: { ru: 1650, ua: 640, kz: 9800 },
        yearly: { ru: 1500, ua: 580, kz: 8900 },
      },
      features: [
        feature("Возможности тарифа «Базовый»"),
        feature("Форма записи на сайт"),
        feature("Отчеты и аналитика"),
        feature("Воронка продаж"),
        feature("Планировщик задач"),
        feature("СМС-рассылки"),
        feature("Расчет зарплаты"),
        feature("Неограниченное количество учеников и пользователей", "user"),
      ],
    },
    {
      code: "custom",
      name: "Филиалы и франшизы",
      color: "green",
      custom: true,
      price:{ ru: 2080, ua: 800, kz: 12400 },
      features: [
        feature("Возможности тарифа «Оптимальный»"),
        feature("Персональный менеджер"),
        feature("Возможность доработки"),
        feature("Подходит крупным центрам"),
        feature("Неограниченное количество учеников и пользователей", "user"),
      ],
    },
  ],
  en: [
    {
      code: "base",
      name: "Basic",
      color: "green",
      custom: false,
      price: {
        monthly: 79,
        quarterly: 71,
        yearly: 67,
      },
      features: [
        feature("Manage up to 50 students"),
        feature("Advanced scheduling"),
        feature("Attendance tracking"),
        feature("Billing and payment processing"),
        feature("Set user rights and permissions"),
        feature("Mobile access"),
      ],
    },
    {
      code: "optimal",
      name: "Standard",
      color: "purple",
      custom: false,
      price: {
        monthly: 119,
        quarterly: 107,
        yearly: 99,
      },
      features: [
        feature("All features of «Basic»"),
        feature("Web application form"),
        feature("Revenue and payment reporting"),
        feature("Task manager"),
        feature("Sms notifications"),
        feature("Automated payroll"),
        feature("Unlimited number of students and users", "user"),
      ],
    },
    {
      code: "custom",
      name: "Custom solution",
      color: "red",
      custom: true,
      price: null,
      features: [
        feature("Manage multiple branches"),
        feature("Dedicated servers hosting"),
        feature("Customised to individual business needs"),
        feature("Relationship manager"),
      ],
    },
  ],
  de: [
    {
      code: "basic",
      name: "Basic",
      color: "green",
      custom: false,
      price: {
        monthly: 67,
        quarterly: 63,
        yearly: 58,
      },
      features: [
        feature("Schülerverwaltung"),
        feature("Anwesenheitskontrolle"),
        feature("Stundenpläne"),
        feature("Online-Anmeldung"),
        feature("Kontakthistorie"),
      ],
    },
    {
      code: "premium",
      name: "Premium",
      color: "purple",
      custom: false,
      price: {
        monthly: 99,
        quarterly: 92,
        yearly: 87,
      },
      features: [
        feature("“Basic” Funktionen"),
        feature("CRM - Interessentenverwaltung"),
        feature("Schüler Mobile App"),
        feature("Debitoren-Management"),
        feature("Finanzbuchhaltung"),
        feature("Lehrer- & Personalverwaltung"),
      ],
    },
    {
      code: "custom",
      name: "Enterprise",
      color: "red",
      custom: true,
      price: null,
      features: [
        feature("Sonderkonditionen"),
        feature("On-premise Möglichkeit"),
        feature("Flexible API"),
        feature("Persönlicher Manager"),
      ],
    },
  ],
}

function feature(name, icon) {
  icon = icon || "checkbox"
  return { name, icon }
}

export default lang => PricingInfo[lang];
