import FeedbackOverlayService from "../services/feedback-overlay-service"
import HeroOverlayService from "../services/heroform-overlay-service"

export function openOverlay(page, block, action, defaultMessage) {
  FeedbackOverlayService.open(buildEventSource(page, block, action), defaultMessage)
}

export function openSuccessOverlay() {
  HeroOverlayService.open()
}

export function buildEventSource(page, block, action) {
  return { page, block, action }
}
