import React from "react"

import styles from "./email.module.scss"

const Email = ({ name, domain, tld }) => (
  <span
    className={styles.email}
    data-name={name}
    data-domain={domain}
    data-tld={tld}
    onClick={(e) => {
      window.location.href = `mailto:${name}@${domain}.${tld}`
      e.preventDefault()
    }}/>
)

export default Email