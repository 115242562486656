import React, { useContext, useEffect, useState } from "react"
import styles from "./header-menu-mobile.module.scss"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import LogoComponent from "../logo"
import CallbackOverlayService from "../../../../services/callback-overlay-service"
import { openOverlay } from "../../../../utils/overlay"
import { PageContext } from "../../../page-context"
import Translate from "../../../../i18n/Translate"
import { withLanguage } from "../../../../i18n/with-language"
import { changeableLink, isPage } from "../definition-page"
import HeaderMenuMoreItem from "./header-menu-more-item"

let noScrollTimeout = null,
  savedScrollY = null

const getActiveClass = (page, featureName, moreName) => {
  return (page === featureName) || (page === moreName) ? styles.active : ""
}

const getRussianMenu = (hideOnNavigate, page, toggleMenu, menuOpen) => <>
  {isPage() ?
    <li className={styles.features_mobile} >
      <Translate id={"menu.features.title"} />
      <input type="checkbox" id="arrow-features2" className={styles.navArrowCheckbox} />
      <label className={styles.navArrow} htmlFor="arrow-features2"></label>
      <ul className={styles.subLevelNav}>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "clients")} to='/feature/clients/'>
            <Translate id={"menu.features.clients"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "subscriptions")} to={`/feature/${changeableLink('subscriptions')}/`}>
            <Translate id={"menu.features.subscriptions"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "tasks")} to='/feature/tasks/'>
            <Translate id={"menu.features.tasks"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "analytics")} to='/feature/analytics/'>
            <Translate id={"menu.features.analytics"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "wages")} to='/feature/wages/'>
            <Translate id={"menu.features.wages"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "mobile")} to='/feature/mobile/'>
            <Translate id={"menu.features.mobile"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "portal")} to={`/feature/${changeableLink('portal')}/`} >
            <Translate id={"menu.features.portal"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "messengers")} to='/feature/messengers/'>
            <Translate id={"menu.features.messengers"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "telephony")} to='/feature/iptelephony/'>
            <Translate id={"menu.features.telephony"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "notifications")} to='/feature/notifications/'>
            <Translate id={"menu.features.notifications"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "onlinecashbox")} to='/feature/onlinecashbox/'>
            <Translate id={"menu.features.onlinecashbox"} />
          </Link>
        </li>
        <li>
          <Link onClick={hideOnNavigate} className={getActiveClass(page, "widgets")} to='/feature/widgets/'>
            <Translate id={"menu.features.widgets"} />
          </Link>
        </li>
      </ul>
    </li> : ''
  }

  <li className={styles.features_mobile} >
    <Translate id={"menu.landings.title"} />
    <input type="checkbox" id="arrow-features" className={styles.navArrowCheckbox} />
    <label className={styles.navArrow} htmlFor="arrow-features"></label>

    <ul className={styles.subLevelNav}>
      <li>
        <Link onClick={hideOnNavigate} className={getActiveClass(page, "education")} to='/landing/education/'>
          <Translate id={"menu.landings.education"} />
        </Link>
      </li>
      <li>
        <Link onClick={hideOnNavigate} className={getActiveClass(page, "sports")} to='/landing/sports/'>
          <Translate id={"menu.landings.sports"} />
        </Link>
      </li>
      <li>
        <Link onClick={hideOnNavigate} className={getActiveClass(page, "creativeschool")} to='/landing/creativeschool/'>
          <Translate id={"menu.landings.creativeschool"} />
        </Link>
      </li>
      <li>
        <Link onClick={hideOnNavigate} className={getActiveClass(page, "beautyschool")} to='/landing/beauty/'>
          <Translate id={"menu.landings.beautyschool"} />
        </Link>
      </li>
    </ul>
  </li>
  <li><Link onClick={hideOnNavigate} to="/pricing/"><Translate id={"menu.pricing"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/franchise/"><Translate id={"menu.franchise"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/advantages/"><Translate id={"menu.more.advantages"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/knowledgebase/"><Translate id={"menu.knowledgebase"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/obuchenie-po-crm/"><Translate id={"menu.more.obuchenie-po-crm"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/obnovleniya-paraplan-crm/"><Translate id={"menu.more.obnovleniya-paraplan-crm"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/about/"><Translate id={"menu.about"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/clients/"><Translate id={"menu.clients"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/blog/"><Translate id={"menu.blog"} /></Link></li>
  <li><Link onClick={hideOnNavigate} to="/partners/"><Translate id={"menu.partners"} /></Link></li>
</>

const getEnglishMenu = (hideOnNavigate) => <>
  <li><a onClick={hideOnNavigate} href="#features"><Translate id={"menu.features.title"} /></a></li>
  <li><a onClick={hideOnNavigate} href="#pricing"><Translate id={"menu.pricing"} /></a></li>
</>

const HeaderMenuMobileComponent = ({ lang, pageClassName }) => {

  const [menuOpen, setMenuOpen] = useState(false)
  const [noScroll, setNoScroll] = useState(false)

  const page = useContext(PageContext)

  function toggleMenu() {
    const nextMenuOpen = !menuOpen
    setMenuOpen(nextMenuOpen)

    if (noScrollTimeout) {
      clearTimeout(noScrollTimeout)
    }

    // prevent visible content repositioning after scroll bar visibility is toggled
    if (nextMenuOpen) {
      savedScrollY = window.scrollY
      noScrollTimeout = setTimeout(() => setNoScroll(nextMenuOpen), 200)
    } else {
      setNoScroll(nextMenuOpen)
    }
  }

  function hideOnNavigate() {
    setMenuOpen(false)
    setNoScroll(false)
    savedScrollY = null
  }

  useEffect(() => {
    if (savedScrollY !== null && !menuOpen) {
      window.scrollTo(0, savedScrollY)
      savedScrollY = null
    }
  })

  const homeLink = lang === "ru" ? "/" : "/en"

  return (
    <>
      <Helmet htmlAttributes={{ class: noScroll ? "no-scroll" : "" }} />
      <div className={styles.mobileMenu}>
        <div className={`${styles.decorate} ${menuOpen ? styles._active : ""}`}></div>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <div className={styles.bar} style={(pageClassName === "about") ? { background: "#4C4F5F" } : {}} />
          <div className={styles.bar} style={(pageClassName === "about") ? { background: "#4C4F5F" } : {}} />
          <div className={styles.bar} style={(pageClassName === "about") ? { background: "#4C4F5F" } : {}} />
        </div>
        <div className={`${styles.overlay} ${menuOpen ? styles.open : ""}`}>
          <div className={styles.control}>
            <Link onClick={hideOnNavigate} to={homeLink}>
              <LogoComponent className={styles.logo} />
            </Link>
            <div className={styles.closeWrapper} onClick={toggleMenu}>
              <div className={styles.close}>
                <div className={styles.bar} />
                <div className={styles.bar} />
              </div>
            </div>
          </div>
          <ul className={styles.topLevelNav}>
            <li className={styles.link_home}><Link onClick={hideOnNavigate} to={homeLink}><Translate id={"menu.home"} /></Link></li>
            {lang === "ru" && getRussianMenu(hideOnNavigate, page)}
            {lang === "en" && getEnglishMenu(hideOnNavigate, page)}
          </ul>
          <ul className={`${styles.topLevelNav} ${styles.callback}`}>
            <li className={styles.header_icon} ><span onClick={() => CallbackOverlayService.open()}>Перезвоните мне</span></li>
            {lang === "ru" && <li className={styles.header_icon} ><a href="/crm"><Translate id={"menu.signIn"} /></a></li>}
          </ul>
          <PageContext.Consumer>
            {page => (
              <button className={styles.cta}
                onClick={() => openOverlay(page, "header", "try")}>
                <Translate id={"menu.ctaTry"} />
              </button>)
            }
          </PageContext.Consumer>
        </div>
      </div>
    </>
  )
}

HeaderMenuMobileComponent.context = PageContext

export default withLanguage(HeaderMenuMobileComponent)
