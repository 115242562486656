import React from "react"

import styles from "./footer-links.module.scss"
import { Link } from "gatsby"
import { withLanguage } from "../../../../i18n/with-language"
import Translate from "../../../../i18n/Translate"

const FooterLinks = ({ className, lang }) => {
  const isRussian = lang === "ru"
  return (
    <div className={`${className} ${styles.docs}`}>
      <ul>
        {isRussian && <li><a target={"_blank"} href='/files/agreement.pdf'>Лицензионное соглашение</a></li>}

        <Translate>
          {
            translate => <li>
              <a target={"_blank"} href={isRussian ? "/files/privacy_policy.pdf" : "/en/policy"}>
                {translate("footer.privacyPolicy")(styles)}
              </a>
            </li>
          }
        </Translate>
        {isRussian && <li><Link to='/payments'>Платежная информация</Link></li>}
        {isRussian && <li><Link to='/documentation'>Документация</Link></li>}
      </ul>
    </div>
  )
}

export default withLanguage(FooterLinks)
