import { initUtm, initYclid } from "../utils/analytics"
import { initPromoCode } from "../utils/promocode"

class PageService {

  constructor() {
    this.initial = true
  }


  onPageOpen(page) {
    window.scrollTo(0, 0)
    this._page = page
    initYclid()
    initPromoCode()
    initUtm()
  }
}

export default new PageService()