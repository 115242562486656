import React from "react"
import { withLanguage } from "../../../../i18n/with-language"

const LogoComponent = ({ className, lang, pageClassName }) => (
  lang === "ru"
    ? <svg className={className} style={{ color: `${pageClassName}` === "about" ? "#7469E9" : ""}} viewBox="0 0 146 51" fill="none"
           xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.4311 44.0839C33.3966 44.0483 33.3699 44.0058 33.3528 43.9593C33.3357 43.9128 33.3285 43.8632 33.3317 43.8137V34.0014C33.3293 33.952 33.3369 33.9026 33.354 33.8562C33.3711 33.8098 33.3973 33.7673 33.4311 33.7312C33.4663 33.6914 33.5103 33.6604 33.5597 33.6406C33.609 33.6209 33.6622 33.6129 33.7152 33.6174H42.1667C42.2197 33.6129 42.2729 33.6209 42.3222 33.6406C42.3716 33.6604 42.4156 33.6914 42.4508 33.7312C42.5229 33.8027 42.5638 33.8998 42.5644 34.0014V43.8137C42.5649 43.8641 42.5551 43.9141 42.5355 43.9605C42.516 44.007 42.4871 44.049 42.4508 44.0839C42.4126 44.1188 42.3677 44.1456 42.3189 44.1627C42.2701 44.1798 42.2183 44.1869 42.1667 44.1834H40.1923C40.0885 44.1873 39.987 44.1518 39.9082 44.0839C39.8719 44.049 39.8431 44.007 39.8235 43.9605C39.804 43.9141 39.7941 43.8641 39.7946 43.8137V36.1345H36.2435V43.8137C36.244 43.8641 36.2342 43.9141 36.2146 43.9605C36.1951 44.007 36.1662 44.049 36.1299 44.0839C36.0511 44.1518 35.9497 44.1873 35.8458 44.1834H33.7152C33.6636 44.1869 33.6118 44.1798 33.563 44.1627C33.5141 44.1456 33.4693 44.1188 33.4311 44.0839Z"
        fill="currentColor"/>
      <path
        d="M47.5786 44.0836C47.5202 44.0216 47.4851 43.9412 47.4791 43.8561C47.4791 43.8561 47.4791 43.7566 47.4791 43.7281L51.016 34.1292C51.0492 33.9952 51.1288 33.8773 51.2406 33.7966C51.3525 33.7159 51.4893 33.6775 51.6268 33.6883H53.9705C54.1074 33.6803 54.2429 33.7198 54.3541 33.8001C54.4654 33.8804 54.5456 33.9966 54.5813 34.1292L58.0897 43.8135C58.0985 43.8557 58.0985 43.8992 58.0897 43.9414C58.0904 43.9843 58.0819 44.0268 58.0647 44.066C58.0476 44.1053 58.0222 44.1404 57.9903 44.169C57.9626 44.1972 57.9294 44.2194 57.8927 44.2341C57.8561 44.2488 57.8167 44.2557 57.7772 44.2543H55.817C55.7081 44.2616 55.5997 44.2338 55.5077 44.1749C55.4156 44.116 55.3449 44.0293 55.3057 43.9272L54.7659 42.5051H50.8171L50.2774 43.9272C50.2382 44.0293 50.1674 44.116 50.0754 44.1749C49.9834 44.2338 49.875 44.2616 49.766 44.2543H47.8058C47.7582 44.243 47.7135 44.2221 47.6744 44.1927C47.6353 44.1634 47.6027 44.1262 47.5786 44.0836ZM51.4563 40.1161H54.1409L52.7205 36.2623L51.4563 40.1161Z"
        fill="currentColor"/>
      <path
        d="M63.1465 44.0834C63.109 44.0525 63.0792 44.0133 63.0595 43.9689C63.0398 43.9244 63.0306 43.876 63.0329 43.8274V34.0151C63.0306 33.9657 63.0382 33.9164 63.0552 33.8699C63.0723 33.8235 63.0985 33.781 63.1323 33.7449C63.1646 33.7054 63.2061 33.6743 63.2531 33.6544C63.3002 33.6346 63.3513 33.6266 63.4022 33.6312H67.6635C68.7605 33.5719 69.8457 33.8823 70.7458 34.5129C71.1219 34.8319 71.4181 35.2349 71.6105 35.6893C71.8029 36.1437 71.8862 36.637 71.8537 37.1295C71.8886 37.616 71.8062 38.1038 71.6134 38.5517C71.4205 38.9997 71.123 39.3947 70.7458 39.7034C69.8394 40.318 68.7565 40.6177 67.6635 40.5567H65.959V43.8274C65.9583 43.8756 65.9479 43.9231 65.9284 43.9671C65.9089 44.0111 65.8806 44.0507 65.8453 44.0834C65.813 44.123 65.7716 44.1541 65.7245 44.1739C65.6775 44.1937 65.6263 44.2017 65.5754 44.1972H63.4022C63.3537 44.1994 63.3053 44.1903 63.2609 44.1705C63.2165 44.1508 63.1774 44.1209 63.1465 44.0834ZM67.6067 38.3951C67.9561 38.4135 68.3001 38.3021 68.5725 38.0823C68.6969 37.9555 68.7929 37.8038 68.8541 37.637C68.9153 37.4703 68.9404 37.2924 68.9276 37.1152C68.9385 36.7684 68.8223 36.4295 68.601 36.1625C68.4706 36.035 68.3143 35.9372 68.1426 35.8758C67.971 35.8144 67.7882 35.791 67.6067 35.8069H65.9163V38.3951H67.6067Z"
        fill="currentColor"/>
      <path
        d="M76.2429 44.0837C76.211 44.0551 76.1856 44.02 76.1684 43.9808C76.1513 43.9415 76.1427 43.899 76.1434 43.8562C76.1346 43.814 76.1346 43.7704 76.1434 43.7282L79.6661 34.1292C79.7041 33.998 79.7849 33.8832 79.8956 33.8033C80.0063 33.7234 80.1405 33.683 80.2769 33.6884H82.6348C82.7699 33.681 82.9033 33.721 83.0122 33.8014C83.1211 33.8819 83.1986 33.9978 83.2314 34.1292L86.754 43.8135C86.754 43.8135 86.754 43.8846 86.754 43.9415C86.7481 44.0265 86.713 44.1069 86.6546 44.169C86.5912 44.2233 86.5107 44.2535 86.4273 44.2543H84.4813C84.3724 44.2616 84.264 44.2338 84.172 44.1749C84.08 44.1161 84.0092 44.0293 83.97 43.9273L83.4302 42.5052H79.4814L78.9275 43.9273C78.8883 44.0293 78.8175 44.1161 78.7255 44.1749C78.6335 44.2338 78.5251 44.2616 78.4161 44.2543H76.4559C76.409 44.2461 76.3648 44.2261 76.3276 44.1963C76.2904 44.1665 76.2612 44.1278 76.2429 44.0837ZM80.1064 40.1161H82.8052L81.47 36.2623L80.1064 40.1161Z"
        fill="currentColor"/>
      <path
        d="M91.7965 44.0836C91.7602 44.0487 91.7313 44.0068 91.7118 43.9603C91.6923 43.9138 91.6824 43.8639 91.6829 43.8134V34.0012C91.6836 33.8996 91.7244 33.8024 91.7965 33.731C91.8279 33.6904 91.8693 33.6586 91.9165 33.6386C91.9638 33.6187 92.0154 33.6113 92.0664 33.6172H100.589C100.691 33.6172 100.788 33.6576 100.86 33.7297C100.932 33.8017 100.972 33.8993 100.972 34.0012V43.8134C100.977 43.8643 100.969 43.9156 100.949 43.9627C100.929 44.0098 100.898 44.0513 100.859 44.0836C100.823 44.1175 100.78 44.1437 100.734 44.1608C100.688 44.1779 100.638 44.1855 100.589 44.1832H98.4299C98.3805 44.1863 98.3309 44.1791 98.2844 44.162C98.238 44.1448 98.1956 44.1182 98.16 44.0836C98.1205 44.0513 98.0894 44.0098 98.0696 43.9627C98.0498 43.9156 98.0419 43.8643 98.0464 43.8134V36.1343H94.5237V43.8134C94.5242 43.8639 94.5143 43.9138 94.4948 43.9603C94.4753 44.0068 94.4464 44.0487 94.4101 44.0836C94.3719 44.1185 94.327 44.1454 94.2782 44.1625C94.2294 44.1796 94.1776 44.1866 94.126 44.1832H92.0664C92.0169 44.1873 91.9671 44.1805 91.9205 44.1633C91.8738 44.1461 91.8315 44.1189 91.7965 44.0836Z"
        fill="currentColor"/>
      <path
        d="M113.572 44.0833C113.536 44.0506 113.508 44.011 113.489 43.967C113.469 43.923 113.459 43.8755 113.458 43.8274V36.1482H110.745V37.4138C110.776 38.7536 110.667 40.093 110.418 41.4098C110.267 42.2312 109.829 42.972 109.183 43.5003C108.407 44.0031 107.492 44.247 106.569 44.1971C106.518 44.1994 106.468 44.1904 106.421 44.1707C106.374 44.151 106.333 44.1212 106.299 44.0833C106.26 44.0497 106.229 44.0083 106.207 43.9618C106.185 43.9152 106.173 43.8646 106.171 43.8131V42.1351C106.171 41.9218 106.299 41.7938 106.555 41.7369C107.08 41.6516 107.464 41.2108 107.677 40.4144C107.938 39.3157 108.053 38.1872 108.018 37.0583V34.0009C108.015 33.9502 108.024 33.8997 108.044 33.8529C108.064 33.8062 108.094 33.7644 108.131 33.7307C108.167 33.6961 108.209 33.6694 108.256 33.6523C108.302 33.6352 108.352 33.6279 108.401 33.6311H115.901C115.954 33.6266 116.007 33.6345 116.057 33.6543C116.106 33.674 116.15 33.7051 116.185 33.7449C116.257 33.8163 116.298 33.9135 116.299 34.0151V43.8274C116.299 43.8778 116.289 43.9277 116.27 43.9742C116.25 44.0207 116.222 44.0627 116.185 44.0976C116.147 44.1325 116.102 44.1593 116.053 44.1764C116.005 44.1935 115.953 44.2005 115.901 44.1971H113.842C113.791 44.1994 113.74 44.1904 113.694 44.1707C113.647 44.151 113.605 44.1212 113.572 44.0833Z"
        fill="currentColor"/>
      <path
        d="M121.313 44.0837C121.281 44.0551 121.256 44.02 121.238 43.9808C121.221 43.9415 121.213 43.899 121.214 43.8562C121.205 43.814 121.205 43.7704 121.214 43.7282L124.75 34.1292C124.783 33.9978 124.861 33.8819 124.97 33.8014C125.078 33.721 125.212 33.681 125.347 33.6884H127.705C127.833 33.6907 127.956 33.7352 128.056 33.8149C128.157 33.8946 128.228 34.005 128.259 34.1292L131.782 43.8135C131.79 43.8557 131.79 43.8993 131.782 43.9415C131.782 43.9843 131.774 44.0268 131.757 44.0661C131.739 44.1053 131.714 44.1405 131.682 44.169C131.624 44.2235 131.548 44.254 131.469 44.2543H129.566C129.457 44.2616 129.348 44.2338 129.256 44.1749C129.164 44.1161 129.093 44.0293 129.054 43.9273L128.5 42.5052H124.552L124.012 43.9273C123.973 44.0293 123.902 44.1161 123.81 44.1749C123.718 44.2338 123.609 44.2616 123.5 44.2543H121.526C121.481 44.2421 121.438 44.2207 121.402 44.1913C121.365 44.1619 121.335 44.1253 121.313 44.0837ZM125.191 40.1161H127.875L126.455 36.2623L125.191 40.1161Z"
        fill="currentColor"/>
      <path
        d="M136.867 44.0839C136.83 44.049 136.802 44.007 136.782 43.9605C136.762 43.9141 136.753 43.8641 136.753 43.8137V34.0014C136.754 33.8998 136.795 33.8027 136.867 33.7312C136.899 33.6916 136.94 33.6606 136.988 33.6407C137.035 33.6209 137.086 33.6129 137.137 33.6175H139.196C139.249 33.6129 139.302 33.6209 139.352 33.6406C139.401 33.6604 139.445 33.6914 139.48 33.7312C139.552 33.8027 139.593 33.8998 139.594 34.0014V37.585H143.159V34.0156C143.159 33.9138 143.2 33.8161 143.272 33.7441C143.343 33.6721 143.441 33.6317 143.543 33.6317H145.602C145.655 33.6292 145.708 33.6381 145.757 33.6577C145.806 33.6773 145.85 33.7073 145.886 33.7454C145.959 33.8169 145.999 33.9141 146 34.0156V43.8279C146.001 43.8783 145.991 43.9283 145.971 43.9748C145.952 44.0212 145.923 44.0632 145.886 44.0981C145.808 44.166 145.706 44.2015 145.602 44.1977H143.543C143.493 44.2 143.444 44.1924 143.398 44.1753C143.351 44.1582 143.309 44.1319 143.273 44.0981C143.233 44.0658 143.202 44.0243 143.182 43.9772C143.163 43.9301 143.155 43.8788 143.159 43.8279V40.1305H139.622V43.8279C139.623 43.8783 139.613 43.9283 139.593 43.9748C139.574 44.0212 139.545 44.0632 139.509 44.0981C139.47 44.133 139.426 44.1598 139.377 44.1769C139.328 44.1941 139.276 44.2011 139.225 44.1977H137.137C137.086 44.199 137.036 44.1895 136.989 44.1699C136.943 44.1503 136.901 44.121 136.867 44.0839Z"
        fill="currentColor"/>
      <path
        d="M29.8126 24.2305C35.5976 23.989 45.3398 25.1361 48.3531 21.4862C52.7439 16.1238 50.4662 10.0699 46.6406 5.82725C41.4978 0.102648 33.1387 -0.737105 27.2385 4.42217C-0.896018 29.0385 -2.95424 25.1471 29.8126 24.2305Z"
        fill="#FFC800"/>
      <path
        d="M48.0732 7.58354C47.7988 7.28716 47.5243 6.99077 47.217 6.70537C45.174 4.70269 42.5345 3.41956 39.6976 3.04997C39.1934 2.98521 38.6856 2.95221 38.1773 2.95117C37.2679 2.97111 36.3748 3.19626 35.5647 3.6098C34.9987 3.85556 34.4717 4.18286 34.0005 4.58128C32.0518 6.15318 30.3819 8.04206 29.0607 10.1687L19.0989 24.6585C22.1341 24.5103 25.6852 24.3457 29.8127 24.2304C35.5976 23.9889 44.8898 25.4818 48.3531 21.4861C52.2829 16.9141 50.9547 11.6506 48.0732 7.58354Z"
        fill="#FD6B56"/>
      <path
        d="M23.9399 29.137C22.7379 34.4774 22.1122 44.7026 18.0122 47.0572C12.0241 50.504 6.53007 47.1834 3.06677 42.6663C-1.59304 36.563 -0.950875 28.1216 5.10304 23.1599C33.9785 -0.512391 30.5701 -3.22375 23.9399 29.137Z"
        fill="#7469E9"/>
      <path
        d="M24.3186 27.3098L24.4997 26.4262L24.615 25.8773C24.6698 25.6248 24.7192 25.3723 24.7741 25.1254C24.829 24.8784 24.8565 24.7521 24.8949 24.5765L25.0431 23.9014C25.0815 23.7148 25.1254 23.5282 25.1638 23.3525C25.2022 23.1769 25.2352 23.0232 25.2736 22.8586C25.3907 22.3207 25.5059 21.7938 25.6194 21.2779V21.2339L25.8334 20.2789C30.0102 1.66165 30.8171 1.22805 16.7169 13.3633L15.482 14.4281L15.4216 14.483L14.1647 15.5533L13.8024 15.8661L12.4358 17.0352L12.3535 17.101L10.9264 18.3085L10.5367 18.6378C9.51037 19.505 8.42912 20.4052 7.29847 21.3492L6.86487 21.706L5.10303 23.1604C3.03502 24.8238 1.49584 27.0531 0.673272 29.5764C-0.149299 32.0997 -0.219698 34.8078 0.470663 37.3704C5.04266 38.6053 8.15469 39.3792 13.8573 37.4637C20.8608 35.1091 23.6325 30.1803 23.7917 29.8346C23.8411 29.5931 23.885 29.3571 23.9399 29.1375C24.0332 28.671 24.1265 28.2154 24.2198 27.7599L24.3186 27.3098Z"
        fill="#589DFC"/>
      <path
        d="M16.2394 21.695C22.8257 18.3744 27.3428 13.2755 28.3143 8.73092C28.4168 8.10989 28.4792 7.48289 28.5009 6.85382C28.4969 6.48565 28.462 6.11845 28.3966 5.75611C27.7984 3.74728 24.5546 6.60135 16.7224 13.3578L15.482 14.4281L15.4216 14.483L14.1647 15.5532L13.8025 15.8661L12.4358 17.0352L12.3535 17.101L10.9265 18.3085L10.5368 18.6378C9.5104 19.505 8.42914 20.4051 7.29849 21.3492L6.8649 21.7059L5.10306 23.1604C4.51116 23.6545 3.96049 24.196 3.45648 24.7796C7.22165 24.9552 11.7827 23.9508 16.2394 21.695Z"
        fill="#25F29C"/>
    </svg>
    : <svg className={className} width="146" height="48" viewBox="0 0 146 48" fill="none"
           xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M29.8126 23.2305C35.5976 22.989 45.3398 24.1361 48.3531 20.4862C52.7439 15.1238 50.4662 9.06992 46.6406 4.82725C41.4978 -0.897352 33.1387 -1.73711 27.2385 3.42217C-0.896018 28.0385 -2.95424 24.1471 29.8126 23.2305Z"
            fill="#FFC800"/>
      <path
        d="M48.0732 6.58354C47.7988 6.28716 47.5243 5.99077 47.217 5.70537C45.174 3.70269 42.5345 2.41956 39.6976 2.04997C39.1934 1.98521 38.6856 1.95221 38.1773 1.95117C37.2679 1.97111 36.3748 2.19626 35.5647 2.6098C34.9987 2.85556 34.4717 3.18286 34.0005 3.58128C32.0518 5.15318 30.3819 7.04206 29.0607 9.16866L19.0989 23.6585C22.1341 23.5103 25.6852 23.3457 29.8127 23.2304C35.5976 22.9889 44.8898 24.4818 48.3531 20.4861C52.2829 15.9141 50.9547 10.6506 48.0732 6.58354Z"
        fill="#FD6B56"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M23.9399 28.1372C22.7379 33.4776 22.1122 43.7028 18.0122 46.0574C12.0241 49.5043 6.53007 46.1837 3.06677 41.6666C-1.59304 35.5633 -0.950875 27.1218 5.10304 22.1601C33.9785 -1.51215 30.5701 -4.22351 23.9399 28.1372Z"
            fill="#7469E9"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M24.3186 26.3098L24.4997 25.4262L24.615 24.8773C24.6698 24.6248 24.7192 24.3723 24.7741 24.1254C24.829 23.8784 24.8565 23.7521 24.8949 23.5765L25.0431 22.9014C25.0815 22.7148 25.1254 22.5282 25.1638 22.3525C25.2022 22.1769 25.2352 22.0232 25.2736 21.8586C25.3907 21.3207 25.5059 20.7938 25.6194 20.2779V20.2339L25.8334 19.2789C30.0102 0.661648 30.8171 0.228049 16.7169 12.3633L15.482 13.4281L15.4216 13.483L14.1647 14.5533L13.8024 14.8661L12.4358 16.0352L12.3535 16.101L10.9264 17.3085L10.5367 17.6378C9.51037 18.505 8.42912 19.4052 7.29847 20.3492L6.86487 20.706L5.10303 22.1604C3.03502 23.8238 1.49584 26.0531 0.673272 28.5764C-0.149299 31.0997 -0.219698 33.8078 0.470663 36.3704C5.04266 37.6053 8.15469 38.3792 13.8573 36.4637C20.8608 34.1091 23.6325 29.1803 23.7917 28.8346C23.8411 28.5931 23.885 28.3571 23.9399 28.1375C24.0332 27.671 24.1265 27.2154 24.2198 26.7599L24.3186 26.3098Z"
            fill="#589DFC"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M16.2394 20.695C22.8257 17.3744 27.3428 12.2755 28.3143 7.73092C28.4168 7.10989 28.4791 6.48289 28.5009 5.85382C28.4969 5.48565 28.462 5.11845 28.3966 4.75611C27.7983 2.74728 24.5546 5.60135 16.7224 12.3578L15.4819 13.4281L15.4216 13.483L14.1647 14.5532L13.8024 14.8661L12.4358 16.0352L12.3535 16.101L10.9264 17.3085L10.5367 17.6378C9.51036 18.505 8.42911 19.4051 7.29846 20.3492L6.86486 20.7059L5.10303 22.1604C4.51113 22.6545 3.96046 23.196 3.45645 23.7796C7.22162 23.9552 11.7826 22.9508 16.2394 20.695Z"
            fill="#25F29C"/>
      <path
        d="M35.5665 39.772V44H33V32H37.8592C38.7946 32 39.6159 32.1648 40.3231 32.4945C41.036 32.8242 41.5835 33.294 41.9656 33.9038C42.3478 34.5082 42.5388 35.1978 42.5388 35.9725C42.5388 37.1484 42.1196 38.0769 41.2812 38.7582C40.4485 39.4341 39.2936 39.772 37.8165 39.772H35.5665ZM35.5665 37.7692H37.8592C38.5379 37.7692 39.0541 37.6154 39.4077 37.3077C39.767 37 39.9467 36.5604 39.9467 35.989C39.9467 35.4011 39.767 34.9258 39.4077 34.5632C39.0484 34.2005 38.5522 34.0137 37.9191 34.0027H35.5665V37.7692Z"
        fill="currentColor"/>
      <path
        d="M53.4692 41.5275H48.9693L48.1138 44H45.3848L50.0216 32H52.3999L57.0623 44H54.3333L53.4692 41.5275ZM49.6623 39.5247H52.7763L51.2107 35.033L49.6623 39.5247Z"
        fill="currentColor"/>
      <path
        d="M66.4871 39.6071H64.4424V44H61.8759V32H66.5042C67.9756 32 69.1106 32.3159 69.9091 32.9478C70.7075 33.5797 71.1068 34.4725 71.1068 35.6264C71.1068 36.4451 70.9214 37.1291 70.5507 37.6786C70.1857 38.2225 69.6296 38.6566 68.8825 38.9808L71.5773 43.8846V44H68.8226L66.4871 39.6071ZM64.4424 37.6044H66.5127C67.1572 37.6044 67.6563 37.4478 68.0099 37.1346C68.3635 36.8159 68.5403 36.3791 68.5403 35.8242C68.5403 35.2582 68.372 34.8132 68.0355 34.489C67.7047 34.1648 67.1943 34.0027 66.5042 34.0027H64.4424V37.6044Z"
        fill="currentColor"/>
      <path
        d="M83.7482 41.5275H79.2483L78.3928 44H75.6637L80.3005 32H82.6788L87.3413 44H84.6123L83.7482 41.5275ZM79.9412 39.5247H83.0552L81.4897 35.033L79.9412 39.5247Z"
        fill="currentColor"/>
      <path
        d="M94.7214 39.772V44H92.1549V32H97.0141C97.9495 32 98.7708 32.1648 99.478 32.4945C100.191 32.8242 100.738 33.294 101.121 33.9038C101.503 34.5082 101.694 35.1978 101.694 35.9725C101.694 37.1484 101.275 38.0769 100.436 38.7582C99.6034 39.4341 98.4485 39.772 96.9714 39.772H94.7214ZM94.7214 37.7692H97.0141C97.6928 37.7692 98.209 37.6154 98.5626 37.3077C98.9219 37 99.1016 36.5604 99.1016 35.989C99.1016 35.4011 98.9219 34.9258 98.5626 34.5632C98.2033 34.2005 97.7071 34.0137 97.074 34.0027H94.7214V37.7692Z"
        fill="currentColor"/>
      <path d="M109.681 42.0137H115.131V44H107.115V32H109.681V42.0137Z" fill="currentColor"/>
      <path
        d="M127.464 41.5275H122.964L122.109 44H119.38L124.017 32H126.395L131.057 44H128.328L127.464 41.5275ZM123.657 39.5247H126.771L125.206 35.033L123.657 39.5247Z"
        fill="currentColor"/>
      <path d="M146 44H143.434L138.437 36.1044V44H135.871V32H138.437L143.442 39.9121V32H146V44Z" fill="currentColor"/>
    </svg>
)

export default withLanguage(LogoComponent)
