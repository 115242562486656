import React, { useContext } from "react"
import { PageContext } from "../../../page-context"
import Translate from "../../../../i18n/Translate"
import styles from "./header-menu-feature-item.module.scss"
import { Link } from "gatsby"

const HeaderMenuFeatureItem = ({ headerColor, featureName, pageClassName, link, landingName }) => {
  const page = useContext(PageContext)
  return <li className={styles.headerMenuFeatureItem} style={{ color: headerColor }}>
    {landingName ?
      <Link
        to={`${link}`}
        activeStyle={{ color: pageClassName === "about" ? "#4C4F5F" : headerColor }}
        className={page === landingName ? styles.active : ""}>
        <Translate id={`menu.landings.${landingName}`} />
      </Link> : ''
    }
    {featureName ?
      <Link
        to={`${link}`}
        activeStyle={{ color: pageClassName === "about" ? "#4C4F5F" : headerColor }}
        className={page === featureName ? styles.active : ""}>
        <Translate id={`menu.features.${featureName}`} />
      </Link> : ''
    }
  </li>
}

export default HeaderMenuFeatureItem
