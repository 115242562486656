import React, { useState } from "react"
import SubscribeOverlayService from "../../../services/subscribe-overlay-service"
import BaseOverlayComponent from "../base-overlay"
import SubscribeFormComponent from "../../forms/subscribe"
import Translate from "../../../i18n/Translate"

let overlayComponent

const SubscribeOverlayComponent = ({ show }) => {

  const [success, setSuccess] = useState(false)

  return (
    <BaseOverlayComponent show={show}
                          title={"Скачать чек-лист"}
                          description={
                            <Translate id={success ? "subscribe.thankYouMessage" : "subscribe.formMessage"}/>
                          }
                          onClose={() => setSuccess(false)}
                          controller={SubscribeOverlayService}
                          ref={overlay => overlayComponent = overlay}>
      <SubscribeFormComponent onSuccess={() => setSuccess(true)}
                              onCloseRequested={() => overlayComponent.close()}/>
    </BaseOverlayComponent>
  )
}

export default SubscribeOverlayComponent
