import { globalHistory } from '@reach/router'

const landingPages = [
    {
        pageName: 'education',
        portalLink: 'portal-education',
        subscriptionsLink: 'subscriptions-education'
    },
    {
        pageName: 'sports',
        portalLink: 'portal-sports',
        subscriptionsLink: 'subscriptions-sports'
    },
    {
        pageName: 'creativeschool',
        portalLink: 'portal-creativeschool',
        subscriptionsLink: 'subscriptions-creativeschool'
    },
    {
        pageName: 'beauty',
        portalLink: 'portal-beauty',
        subscriptionsLink: 'subscriptions-beauty'
    },
  /*  {
        pageName: 'feature/',
        portalLink: 'portal',
        subscriptionsLink: 'subscriptions'
    },*/
]

function getActivePage() {
    return globalHistory.location.pathname
}

const checkLink = (link) => {
    return getActivePage().includes(link)
}

export function changeableLink(defaultValue) {
    let newLink = landingPages.find(element => {
       return checkLink(element.pageName)
    });
    if(newLink) {
        sessionStorage.setItem('portal', newLink.portalLink)
        sessionStorage.setItem('subscriptions', newLink.subscriptionsLink)
    }
    if (sessionStorage.getItem(defaultValue)) {
        return sessionStorage.getItem(defaultValue)
    }
    return defaultValue
}

export function isPage() {
    return getActivePage().includes('/landing/') || getActivePage().includes('/feature/')
}