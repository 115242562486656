import { LANGUAGES, MESSAGES } from "./messages"

export function getMessage(messageId, lang) {
  const index = LANGUAGES.indexOf(lang)
  if (index === -1) {
    throw new Error(`Unknown language ${lang}`)
  }

  let obj = MESSAGES
  for (let i = 0, path = messageId.split("."), len = path.length; i < len; i++) {
    obj = obj[path[i]]
  }

  return obj[index]

}

  


