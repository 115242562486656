export function initYclid() {
  var yclid = getParam("yclid")
  if (yclid) {
    setCookie("yclid", yclid, 90)
  }
}

export function initUtm() {
  
  function setUtmCookieIfPresent(name) {
    var utmPart = getParam(name)
    if (utmPart) {
      setCookie(name, utmPart, 3);
    }
  }

  setUtmCookieIfPresent("utm_source");
  setUtmCookieIfPresent("utm_medium");
  setUtmCookieIfPresent("utm_campaign");
  setUtmCookieIfPresent("utm_term");
  setUtmCookieIfPresent("utm_content");
}

export function getUtm() {
  var source = readCookie("utm_source");
  var medium = readCookie("utm_medium");
  var campaign = readCookie("utm_campaign");
  var term = readCookie("utm_term");
  var content = readCookie("utm_content");

  if (source || medium || campaign || term || content) {
    return { source, medium, campaign, term, content }
  }

  return null
}

export function readCookie(name) {
  var n = name + "="
  var cookie = document.cookie.split(";")
  for (var i = 0; i < cookie.length; i++) {
    var c = cookie[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(n) === 0) {
      return c.substring(n.length, c.length)
    }
  }
  return null
}

function setCookie(name, value, days) {
  var date = new Date()
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
  var expires = "; expires=" + date.toGMTString()
  document.cookie = name + "=" + value + expires + ";path=/"
}

function getParam(p) {
  var match = RegExp("[?&]" + p + "=([^&]*)").exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, " "))
}
