import React from "react"

import styles from "./checkbox.module.scss"

const Checkbox = ({ children, checked, disabled, onChange, blue, green }) => (
  <label className={`${styles.checkbox} ${blue ? styles.checkboxBlue : ""} ${green ? styles.checkboxGreen : ""}`}>
    <input type='checkbox'
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <div className={styles.control} />
    <div className={styles.label}>{children}</div>
  </label>
)

export default Checkbox