import React from "react"
import styles from "./subscribe-form.module.scss"
import Input from "../../controls/input"
import subscribeService from "../../../services/subscribe-service"
import LoaderComponent from "../shared/loader"
import SuccessComponent from "../shared/success"
import Translate from "../../../i18n/Translate"
import { ReCaptcha } from "react-recaptcha-google"
import { graphql, useStaticQuery } from "gatsby"

function useCaptchaToken() {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            captchaToken
          }
        }
      }
    `,
  )
  return site.siteMetadata.captchaToken;
}

class SubscribeFormComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      inProgress: false,
      email: "",
      validation: null,
      showCaptcha: false,
      captcha: null,
    }
  }

  sendForm = () => {
    const { email, inProgress, captcha } = this.state
    if (inProgress) {
      return
    }

    this.setState({ inProgress: true })
    subscribeService.send(email, captcha)
      .then(validation => this.setState({ validation }))
      .then(() => this.setState({ inProgress: false }))
      .then(() => this.isSuccess ? this.props.onSuccess() : null)
      .then(() => this.setState({
        showCaptcha: this.needToShowCaptcha,
        captcha: null,
      }))
  }

  handleFieldChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.sendForm()
    }
  }

  get isSuccess() {
    const { validation } = this.state
    return validation && validation.success
  }

  // Генерирует уникальный elementId для ReCaptcha, чтобы могло рендериться несколько элементов
  generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`
  }

  get needToShowCaptcha() {
    const { validation } = this.state
    return validation && validation.fieldErrorCodeList && validation.fieldErrorCodeList.captcha
  }

  onLoadRecaptcha() {
    if (this.captchaElement) {
      this.captchaElement.reset()
    }
  }

  handleChange = recaptchaToken => {
    this.setState({ captcha: recaptchaToken })
  }

  render() {
    const { validation, inProgress, showCaptcha } = this.state
    const { onCloseRequested } = this.props

    return <Translate>
      {
        translate => !validation || !validation.success
          ? (
            <div className={styles.form}>
              <Input name={"email"}
                     validation={validation}
                     placeholder={translate("form.email")}
                     value={this.state.email}
                     onChange={this.handleFieldChange("email")}
                     inputClassName={"gtm_subscribe-input"}
                     onKeyDown={this.handleKeyDown}
                     maxLength={255}/>
              <button className={`${styles.send} gtm_subscribe-btn`}
                      onClick={this.sendForm}>
                {inProgress ? <LoaderComponent/> : translate("form.subscribe")}
              </button>
              {
                showCaptcha ? <ReCaptcha ref={(el) => this.captchaElement = el}
                                         elementID={this.generateKey()}
                                         hl={translate("form.captchaLanguage")}
                                         size="normal"
                                         theme="light"
                                         onloadCallback={this.onLoadRecaptcha}
                                         verifyCallback={this.handleChange}
                                         sitekey={useCaptchaToken()}/>
                  : <div hidden={true}/>
              }
            </div>
          )
          : (
            <>
              <SuccessComponent/>
              {
                onCloseRequested
                  ? <div className={styles.closeButton}>
                    <button className={styles.send}
                            onClick={onCloseRequested}>
                      {translate("form.close")}
                    </button>
                  </div>
                  : null
              }

            </>
          )
      }
    </Translate>
  }
}

export default SubscribeFormComponent
