export class OverlayService {

  constructor() {
    this._open = false
    this._from = null
    this._listeners = []
  }

  open(from) {
    this.toggle(true, from)
  }

  close() {
    this.toggle(false, null)
  }

  toggle(open, from) {
    this._open = open
    this._from = from
    this.notify()
  }

  addListener(listener) {
    if (this._listeners.indexOf(listener) !== -1) {
      return
    }
    this._listeners.push(listener)
  }

  removeListener(listener) {
    const index = this._listeners.indexOf(listener)
    if (index === -1) {
      return
    }
    this._listeners.splice(index, 1)
  }

  notify() {
    this._listeners.forEach(listener => listener(this._open))
  }
}