import React, { useContext } from "react"
import { PageContext } from "../../../page-context"
import Translate from "../../../../i18n/Translate"
import styles from "./header-menu-more-item.module.scss"
import { Link } from "gatsby"

const HeaderMenuMoreItem = ({ headerColor, moreName, pageClassName }) => {
  const page = useContext(PageContext)
  return <li className={styles.headerMenuMoreItem} style={{ color: pageClassName === "about" ? "#4C4F5F" : headerColor}}>
    <Link to={`/${moreName}`} className={page === moreName ? styles.active : ""}>
      <Translate id={`menu.more.${moreName}`}/>
    </Link>
  </li>
}

export default HeaderMenuMoreItem
