import React from "react"

import styles from "./success.module.scss"

const SuccessComponent = () => (
  <div className={styles.successCheckmark}>
    <div className={styles.checkIcon}>
      <span className={`${styles.iconLine} ${styles.lineTip}`}/>
      <span className={`${styles.iconLine} ${styles.lineLong}`}/>
      <div className={styles.iconCircle}/>
      <div className={styles.iconFix}/>
    </div>
  </div>
)

export default SuccessComponent