import React from "react"
import styles from "./footer-social.module.scss"

const FooterSocial = ({ className, imageClassName }) => (
  <>
    <a className={`${className || ""} ${styles.footer_social}`}
       target={"_blank"} rel="nofollow"
       href={"https://vk.com/paraplancrm"}>
      <img
        alt='Группа ВКонтакте'
        className={imageClassName || ""}
        src={require("../../../../images/vk.svg")}/>
    </a>
    <a className={`${className || ""} ${styles.footer_social}`}
       target={"_blank"} rel="nofollow"
       href={"https://www.youtube.com/channel/UCHyuMKQS-MQeJTS00YcPo6g"}>
      <img
        alt='Youtube канал'
        className={imageClassName || ""}
        src={require("../../../../images/youtube.svg")}/>
    </a>
    <a className={`${className || ""} ${styles.footer_social}`}
       target={"_blank"} rel="nofollow"
       href={"https://rutube.ru/channel/41659452/"}>
      <img
        alt='Rutube канал'
        className={imageClassName || ""}
        src={require("../../../../images/rutube.svg")}/>
    </a>
  </>
)

export default FooterSocial
