import React from "react"

import styles from "./header-cta.module.scss"
import { PageContext } from "../../../page-context"
import { openOverlay } from "../../../../utils/overlay"
import Translate from "../../../../i18n/Translate"

// todo context for header color
const HeaderCtaComponent = ({ headerColor, pageClassName }) => (
  <PageContext.Consumer>
    {
      page => (
        <button className={`${styles.headerCta} ${pageClassName === "about" ? styles.aboutCta : ""}`}
                style={{ color: headerColor}}
                onClick={() => openOverlay(page, "header", "try")}>
          <Translate id="menu.cta"/>
        </button>
      )
    }
  </PageContext.Consumer>
)

export default HeaderCtaComponent
