import React from "react"
import styles from "./textarea.module.scss"

function hasError(validation, name) {
  return validation && !validation.success && validation.fieldErrorCodeList[name]
}

const TextArea = ({ validation, name, value, textAreaClassName, ...props }) => (
  <div className={`${styles.wrapper} ${hasError(validation, name) ? styles.hasError : ""}`}>
    <textarea className={`${styles.input} ${textAreaClassName}`} {...props}/>
    <div className={styles.error}>Заполните поле</div>
  </div>
)

export default TextArea