import React from "react"

import styles from "./loader.module.scss"

const LoaderComponent = () => (
  <div className={styles.loader}>
    <div/>
    <div/>
    <div/>
    <div/>
  </div>
)

export default LoaderComponent