import React from "react"
import styles from "./footer-legal.module.scss"
import { withLanguage } from "../../../../i18n/with-language"

const FooterLegal = ({ className, lang }) => (
  <div className={`${styles.footerLegal} ${className}`}>
    {
      lang === "ru"
        ? (
          <>
            <div>Copyright © 2008-{new Date().getFullYear()} Haulmont.</div>
            <div>Все права защищены. ООО «Холмонт Бизнес Решения»</div>
            <div>ИНН 6321416763, КПП 632101001, ОГРН 1166313133293 </div>
          </>
        )
        : <div>Copyright © 2008-{new Date().getFullYear()} Haulmont. All rights reserved.</div>
    }
  </div>
)

export default withLanguage(FooterLegal)
